export default class HandCardModel {

    id;
    value;

    constructor(id, value) {
        this.id = id;
        this.value = value;
    }

}