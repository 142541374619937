import React from "react";

export default class NoResult extends React.Component {

    render() {
        return(
            <p>Aucun élément</p>
        );
    }

}