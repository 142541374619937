export default class WordModel {

    id;
    cards;

    constructor(id, cards) {
        this.id = id;
        this.cards = cards;
    }

}